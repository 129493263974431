const countryPrefix = [
    {
        code: '+54',
        prefix: 'AR',
        name: 'Argentina'
    },
    {
        code: '+591',
        prefix: 'BO',
        name: 'Bolivia'
    },
    {
        code: '+55',
        prefix: 'BR',
        name: 'Brasil'
    },
    {
        code: '+1',
        prefix: 'CA',
        name: 'Canada'
    },
    {
        code: '+56',
        prefix: 'CL',
        name: 'Chile'
    },
    {
        code: '+57',
        prefix: 'CO',
        name: 'Colombia'
    },
    {
        code: '+53',
        prefix: 'CU',
        name: 'Cuba'
    },
    {
        code: '+593',
        prefix: 'EC',
        name: 'Ecuador'
    },
    {
        code: '+34',
        prefix: 'ES',
        name: 'España'
    },
    {
        code: '+52',
        prefix: 'MX',
        name: 'México'
    },
    {
        code: '+507',
        prefix: 'PA',
        name: 'Panama'
    },
    {
        code: '+51',
        prefix: 'PE',
        name: 'Perú'
    },
    {
        code: '+1',
        prefix: 'US',
        name: 'USA'
    },
    {
        code: '+58',
        prefix: 'VE',
        name: 'Venezuela'
    },
]

export default countryPrefix