import Vue from 'vue'
import vmodal from 'vue-js-modal'
import VueBus from 'vue-bus'

import WhatsappDialog from './components/WhatsappDialog'

Vue.use(vmodal)
Vue.use(VueBus)

Vue.config.productionTip = false

const appContainer = document.querySelector('#whatsapp-app-container')

if(appContainer !== undefined) {
	new Vue({
		render: h => h(WhatsappDialog, {
			props: {
				type: appContainer.dataset.type,
				endpoint: appContainer.dataset.endpoint,
				proyect: appContainer.dataset.proyect,
				phone: appContainer.dataset.phone
			}
		}),
	}).$mount('#whatsapp-app-container')
}
