<template>
    <div class="modal-content">
        <form class="mb-0" @submit.prevent="validationForm">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    <img src="../../assets/whatsapp-panel.png" alt="Whatsapp">
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hide">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body pb-0">
                <div class="row">
                    <div class="col-12 form-group">
                        <label for="modal-whatsapp-name">Tu Nombre</label>
                        <input 
                            type="text" 
                            name="modal-whatsapp-name" 
                            class="form-control form-control-sm required" 
                            id="modal-whatsapp-name" 
                            required="required" 
                            v-model="modalFormWhatssap.name"
                        />
                    </div>
                </div>             
                <div class="row">
                    <div class="col-12 form-group">
                        <label for="modal-whatsapp-email">Tu Email</label>
                        <input 
                            type="email" 
                            name="modal-whatsapp-email" 
                            class="form-control form-control-sm required" 
                            id="modal-whatsapp-email" 
                            required="required" 
                            v-model="modalFormWhatssap.email"
                        />
                    </div>
                </div>                
                <div class="row">
                    <div class="col-12 form-group">
                        <label for="modal-whatsapp-message">Escríbenos un mensaje</label>
                        <textarea 
                            name="modal-whatsapp-message" 
                            id="modal-whatsapp-message" 
                            class="form-control form-control-sm required valid" 
                            rows="2" 
                            required="required"
                            v-model="modalFormWhatssap.message"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" name="modal-whatsapp-submit" class="btn btn-block btn-ws-submit" :disabled="isBtnSubmitDisabled">Iniciar Chat</button>
            </div>
        </form>
    </div>
</template>

<script>
import prefixPhones from './../../utils/prefixPhones'

export default {
    data() {
        return {
            modalFormWhatssap: {
                name: '',
                email: '',
                message: ''
            },

            isBtnSubmitDisabled: false,
            prefixPhones: prefixPhones
        }
    },
    methods: {
        init() {
            this.modalFormWhatssap = {
                name: '',
                email: '',
                message: ''
            }
        },
        hide () {
            this.$bus.emit('eventHideWhatsappModal');
        },
        validationForm() {
            let flag = true

            for (let key in this.modalFormWhatssap) {
                if (this.modalFormWhatssap[key] === '') {
                    flag = false
                }
            }

            if(flag) {
                this.isBtnSubmitDisabled = true;

                this.$bus.emit('eventWhatsappModalFormSubmit', this.modalFormWhatssap);
                
                this.isBtnSubmitDisabled = false;
                this.init()
            }
        },
    }
}
</script>

<style scoped>
    .modal-content {
        border: none;    
    }

    .modal-content .modal-header {
        background-color: #25D366;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        border-top-left-radius: none;
        border-top-right-radius: none;
    }

    .modal-content .modal-header .close {
        font-size: 2em;
        color: #fff;
        opacity: 1;
    }

    .modal-content .modal-body .form-control:focus {
        border-color: #00c9a3;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }

    .modal-content .modal-footer {
        border-top: none;
    }

    .modal-content .modal-footer .btn-ws-submit {
        background-color: #25D366;
        color: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);    
    }

    .modal-content .modal-footer .btn-ws-submit:hover {
        background-color: #1a9046;
    }
</style>